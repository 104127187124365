<template>
  <div class="patrolPointList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :page-size="200"
      :isMultiSelect="true"
      exportMethod="delay"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="createPoint"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="巡更点名称" v-model="searchParams.name"></v-input>
        <v-input label="设备编号" v-model="searchParams.bluetoothNum"></v-input>
        <v-select label="关联方式" v-model="searchParams.deviceType" :options="patrolPointTypeOps"></v-select>
        <v-input label="设备位置" v-model="searchParams.location"></v-input>
        <v-select2 label="所属公司" v-bind="regionParams" v-model="searchParams.regionId" @onChange="onRegionIdChange" />
        <v-select label="一级分类" v-model="searchParams.parentCategoryId" :options="firstOps" @change='getSecondOpsFun'></v-select>
        <v-select label="二级分类" v-model="searchParams.categoryId" :options="secondOps"></v-select>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
         <v-select label="设备状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker label="创建时间段" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="editPoint(scope.row)"></v-button>
        <v-button text="删除" type="text" permission="delete" @click="delPoint(scope.row)"></v-button>
        <!-- permission="addQRcode" TODO 待修改 -->
        <v-button text="生成二维码" type="text"  v-show="isShowQRbtn(scope.row)" @click="addQRcodeClick(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量生成二维码" @click="batchCreateQrcode(scope.selectedData)" v-show="isShowQRBatchbtn"></v-button>
      </template>
    </list>
    <el-dialog title="下载二维码" width="600px" :visible.sync="qrCodeConfig" @closed="qrCodeClosed">
      <div class="qr-wrapper">
        <h1 class="qr-title">名称: {{ qrCodeObj.name }}</h1>
        <div class="qrcode-img-wrapper">
          <div v-if="qrCodeId" :id="qrCodeId" class="img"></div>
        </div>
        <v-button text="保存到本地" type="success" @click="downloadQRCode"></v-button>
      </div>
    </el-dialog>
    <qrcode-collection :visible.sync="dialogQrcodeList"
                       :qrcode-list="qrcodeList">
      <template #qrcodeSlot="scope">
        <div style="display: flex;flex-direction: column;">
          <span>{{scope.row.name}}</span>
        </div>
      </template>
    </qrcode-collection>
  </div>
</template>

<script>
import { getListURL, exportListURL, delPointURL } from './api'
import { getFirstOpts, getSecondOps, communityParams } from './../map'
import { regionParams } from 'common/select2Params'
import { statusOps, statusMap, patrolPointTypeMap, patrolPointTypeOps } from './map'
import QRCode from 'qrcodejs2'
import { QrcodeCollection } from 'components/bussiness'
import { downloadHelper } from '@/common/utils.js'
import moment from 'moment'

export default {
  name: 'patrolPointList',
  components: {
    QrcodeCollection
  },
  computed: {
    // videoPermission () {
    //   const permission = this.$store.getters.getPermission('videoControl')
    //   if (!permission) {
    //     this.searchParams.deviceType = 1
    //   }
    //   return permission
    // },
    QRCodePermission () {
      return this.$store.getters.getPermission('QRCodeControl')
    },
    NFCPermission () {
      return this.$store.getters.getPermission('NFCControl')
    },
    patrolPointTypeOps () {
      return patrolPointTypeOps.call(this)
    },
    patrolPointTypeMap () {
      return patrolPointTypeMap.call(this)
    },
    isShowQRBatchbtn () {
      return this.searchParams.deviceType === 3
    }
  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        name: '',
        bluetoothNum: '',
        location: '',
        parentCategoryId: undefined,
        categoryId: undefined,
        communityId: '',
        regionId: '',
        status: null,
        startTime: this.calculateDate(false, 1),
        endTime: this.calculateDate(true, 0),
        deviceType: null
      },
      levelOps: [{
        text: '全部',
        value: undefined
      }],
      firstOps: [],
      secondOps: [{
        text: '全部',
        value: undefined
      }],
      communityParams: communityParams,
      regionParams,
      statusOps: statusOps,
      headers: [
        {
          prop: 'name',
          label: '巡更点名称'
        },
        {
          prop: 'parentName',
          label: '一级分类'
        },
        {
          prop: 'categoryName',
          label: '二级分类'
        },
        {
          prop: 'bluetoothNum',
          label: '设备编号'
        },
        {
          prop: '',
          label: '关联方式',
          formatter: (row) => {
            return this.patrolPointTypeMap[row.patrolPointType] || '未知'
          }
        },
        {
          prop: 'location',
          label: '设备位置'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'blockName',
          label: '所属组团'
        },
        {
          prop: 'bluetoothStatus',
          label: '设备状态',
          formatter: (val) => {
            if (val.patrolPointType === 3) {
              return ''
            }
            return statusMap[val.bluetoothStatus]
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      extraParams: {
        deviceType: 2
      },
      qrCodeConfig: false,
      qrCodeObj: {
        name: '',
        code: '',
        address: ''
      },
      qrCodeId: undefined,
      dialogQrcodeList: false,
      qrcodeList: []
    }
  },
  async created () {
    let options = await getFirstOpts()
    this.firstOps = this.levelOps.concat(options)
  },
  mounted () {
    // 检索条件回显联动
    if (this.searchParams.parentCategoryId) {
      getSecondOps(this.searchParams.parentCategoryId, this.secondOps)
    }
  },
  methods: {
    getSecondOpsFun (value) {
      this.searchParams.categoryId = undefined
      this.secondOps = [{
        text: '全部',
        value: undefined
      }]
      if (value) {
        getSecondOps(value, this.secondOps)
      }
    },
    createPoint () {
      this.$router.push({
        name: 'patrolDotMgtForm',
        query: {
          type: this.searchParams.deviceType
        }
      })
    },
    editPoint (row) {
      this.$router.push({
        name: 'patrolDotMgtForm',
        query: {
          id: row.id,
          type: this.searchParams.deviceType
        }
      })
    },
    delPoint (row) {
      let _this_ = this
      this.$confirm('确定删除该巡查点？', {
        title: '提示'
      }).then(confirm => {
        if (confirm) {
          _this_.$axios.delete(delPointURL + row.id).then(res => {
            if (res.status === 100) {
              _this_.$refs.list.searchData()
            }
          })
        }
      })
    },
    async onRegionIdChange (region, isBack) {
      let ops = await getFirstOpts({ regionId: this.searchParams.regionId })
      this.firstOps = [
        {
          text: '全部',
          value: undefined
        },
        ...ops
      ]
      if (!isBack) {
        this.searchParams.parentCategoryId = undefined
      }
    },
    // 是否展示二维码按钮
    /**
     * @param { Number } patrolPointType  1 蓝牙 2视频 3二维码
     */
    isShowQRbtn (row) {
      const { patrolPointType } = row;
      return patrolPointType == 3
    },
    // 关闭二维码
    qrCodeClosed () {
      this.qrCodeId = ''
    },
    // 生成二维码
    addQRcodeClick (row) {
      this.createQrcode(row)
    },
    createQrcode (row) {
      let data = row.qRCode
      this.qrCodeObj = row
      this.qrCodeUrl = data
      this.qrCodeConfig = true
      this.qrCodeId = `img_${data}`
      this.$nextTick(() => {
        new QRCode(this.qrCodeId, { // eslint-disable-line
          width: 200,
          height: 200,
          text: data
        })
        this.showQRCodeDialog();
      }, 20)
    },
    showQRCodeDialog () {
      this.qrCodeConfig = true
    },
    // 保存二维码到本地
    downloadQRCode () {
      let qrcodeEle = document.getElementById(this.qrCodeId)
      let url = qrcodeEle.children[1].src
      downloadHelper.downloadBySrc({
        src: url,
        fileName: this.qrCodeObj.name
      })
      this.dialogQrcode = false
    },
    async batchCreateQrcode (selected) {
      let list = [];
      selected.data.forEach((item) => {
        if (item.patrolPointType === 3) {
          list.push(item)
        }
      })
      let len = list.length;
      if (!len) {
        this.$alert('请先选择可操作的数据')
        return
      }
      let isOk = await this.$confirm('确定执行该操作？')
      if (isOk) {
        this.qrcodeList = list.map(item => {
          return {
            ...item,
            qrCode: item.qRCode,
            encryCode: item.qRCode
          }
        })
        this.dialogQrcodeList = true
      }
    },
    // 计算时间,type类型，days天数
    calculateDate(add, days){
      // true加 false减
      if (add) {
        return moment().add(days, 'd').format('YYYY-MM-DD HH:mm')
      } else {
        return moment().subtract(days, 'months').format('YYYY-MM-DD HH:mm')
      }
    },
  }
}
</script>

<style lang="scss">
.qr-wrapper {
  .qr-title {
    padding-bottom: 20px;
    border-bottom: 1px dashed #000;
  }
  .qrcode-img-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px 0 30px;
  }
}
</style>
