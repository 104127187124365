import { generateMapByOpts, mapHelper } from 'common/utils'
const statusOps = [{
  text: '全部',
  value: null
},
{
  text: '关闭',
  value: 0
},
{
  text: '开启',
  value: 1
}]

const statusMap = generateMapByOpts(statusOps)

// 巡更点类型
const typeOps = [
  {
    text: '蓝牙',
    value: 1
  },
  {
    text: '视频',
    value: 2
  },
  {
    text: '二维码',
    value: 3
  }
]
const typeMap = generateMapByOpts(typeOps)

const patrolPointTypeOps = function (type) {
  let arr = [];
  if (type === 1) {
    arr.push({
      text: '请选择',
      value: null
    })
  }

    arr.push({
      text: '全部',
      value: null
    })

  arr.push({
    text: '蓝牙',
    value: 1
  })
  if (this.videoPermission) {
    arr.push({
      text: '视频',
      value: 2
    })
  }
  if (this.QRCodePermission){
    arr.push({
      text: '二维码',
      value: 3
    })
  }
  if (this.NFCPermission) {
    arr.push({
      text: 'NFC',
      value: 4
    })
  }
  return arr
}

const patrolPointTypeMap = function () {
  return generateMapByOpts(patrolPointTypeOps.apply(this, arguments))
}

export {
  statusOps,
  statusMap,
  typeMap,
  patrolPointTypeOps,
  patrolPointTypeMap
}
